

export default function Footer() {

    return (
        <div className="h-24 min-w-full bg-slate-900 font-sans text-white container flex flex-col justify-center items-center">
            <p className="text-md">Copyright © 2024 Serbius Pty Limited</p>
            <p className="md:text-xs sm:text-[8px] md:w-1/2 sm:w-5/6 text-center opacity-55">Serbius Pty Limited is an authorised representative of Insight Charity Fundraising Services (
Commercial Raffle Organiser’s License number K09000011.)
. Learn more at mycharityraffle.com.au or give us a call on 02 9006 9640.</p>
        </div>
    )
}